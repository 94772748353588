import { FunctionComponent } from "react";
import useMediaQuery from "../../hooks/useMediaQuery";
import { motion } from "framer-motion";
import SocialMediaIcons from "../../components/SocialMediaIcons";

const Landing: FunctionComponent = () => {
  const isAboveMediumScreens = useMediaQuery("(min-width: 1060px)");
  return (
    <section
      id="home"
      className="md:flex md:justify-between md:items-center md:h-full gap-16 py-10"
    >
      <div className="md:order-2 flex justify-center basis-3/5 z-10 mt-16 md:mt-32">
        {isAboveMediumScreens ? (
          <div
            className="relative flex flex-row z-0 ml-20 before:absolute before:-top-20 before:-left-20
                                   before:rounded-[23px] before:w-full max-w-[740px] min-w-[740px]  before:h-[400px] 
                                   before:border-0 before:border-blue before:z-[-1] rounded-[23px] border-2 py-4 border-[#765CE5] px-4"
          >
            <img
              className="transition duration-500 z-10 w-full
                        max-w-[500px] max-h-[500px] md:max-w-[600px] "
              alt="truMain"
              src="assets/poster3.png"
            />
            <img
              className="transition duration-500 z-10 w-full
                        max-w-[500px] max-h-[500px] md:max-w-[600px]"
              alt="truMain"
              src="assets/poster1.png"
            />
          </div>
        ) : (
          <div className=" border-2 py-4 border-[#765CE5] px-4 rounded-[23px] flex flex-row">
            <img
              className=" transition duration-500 z-10 w-[50%]
                    max-w-[180px]  max-h-[500px]"
              alt="truMain"
              src="assets/poster3.png"
            />
            <img
              className=" transition duration-500 z-10 w-[50%]
                    max-w-[180px] md:max-w-[500px]  max-h-[500px]"
              alt="truMain"
              src="assets/poster1.png"
            />
          </div>
        )}
      </div>
      <div className="z-30 basis-2/5 mt-12 md:mt-32">
        <motion.div
          className="border-b-2"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, x: -50 },
            visible: { opacity: 1, x: 0 },
          }}
        >
          <p className="text-6xl font-poppins z-10 text-center md:text-start mb-4">
            Do <span className="text-neonGreen">Less </span>
            <span
              className="xs:relative xs:text-deep-blue xs:font-semibold z-20 xs:before:content-brush2
                                            before:absolute before:-left-[25px] before:-top-[70px] before:z-[-1]"
            >
              <br />Excel <span className="text-neonGreen">More</span>
            </span>
          </p>
        </motion.div>
        <motion.div
          className="flex flex-col mt-5 justify-center md:justify-start"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ delay: 0.2, duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, x: -50 },
            visible: { opacity: 1, x: 0 },
          }}
        >
          <div className=""></div>
          <div className="w-full mt-4 border-2 border-purple px-4 py-4 rounded-[10px]">
            <p className="text-white text-3xl font-semibold text-center font-poppins py-10 leading-10 ">
              Empowering businesses and individuals to excel in the digital age
            </p>
          </div>
        </motion.div>

        <motion.div
          className="flex mt-5 justify-center md:justify-start"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ delay: 0.4, duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, x: -50 },
            visible: { opacity: 1, x: 0 },
          }}
        >
          <SocialMediaIcons />
        </motion.div>
      </div>
    </section>
  );
};

export default Landing;
