import { FunctionComponent, useState } from "react";
import useMediaQuery from "../hooks/useMediaQuery";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";

export interface INavbarRoute {
  page: string;
  selectedPage: any;
  setSelectedPage: (value: string) => void;
  isTopOfPage: boolean;
}

const NavbarRoute: FunctionComponent<INavbarRoute> = (props) => {
  console.log(props.isTopOfPage, "TEST");
  const [isMenuToggled, setIsMenuToggled] = useState(false);
  const isAboveSmallScreens = useMediaQuery("(min-width: 768px)");
  const navbarBackground = props.isTopOfPage ? "bg-black" : "";
  return (
    <nav className={`${navbarBackground} z-40 w-full fixed top-0 py-6`}>
      <div className="flex flex-row items-center justify-between mx-auto w-5/6">
        <div className="max-h-[40px]">
          <img
            alt="logo"
            className="w-[150px] -mt-[3em]"
            src="../assets/logo.png"
          />
        </div>
        {/* desktop menu */}
        {isAboveSmallScreens ? (
          <div className="flex justify-between gap-16 font-poppins text-lg font-semibold">
            <Link to="/">
              <div className="flex flex-row justify-center items-center hover:text-neonGreen transition duration-500">
                <Icon
                  icon="eva:arrow-ios-back-fill"
                  className="w-[40px] h-[30px]"
                />
                <p>HomePage</p>
              </div>
            </Link>
            <Link
              to="/about"
              className={`${
                props.selectedPage === "/about"
                  ? "text-neonGreen border-b-2 border-l-2 rounded-[8px] px-5 border-green"
                  : ""
              } 
                        hover:text-neonGreen transition duration-500`}
            >
              About us
            </Link>

            <Link
              to="/blog"
              className={`${
                props.selectedPage === "/products"
                  ? "text-neonGreen border-b-2 border-l-2 rounded-[8px] px-5 border-green"
                  : ""
              } 
                        hover:text-neonGreen transition duration-500`}
            >
              Our Products
            </Link>
          </div>
        ) : (
          <button
            className="rounded-full bg-green p-2"
            onClick={() => setIsMenuToggled(!isMenuToggled)}
          >
            <img alt="menu-icon" src="../assets/menu-icon.svg" />
          </button>
        )}
        {/* mobile menu */}
        {!isAboveSmallScreens && isMenuToggled && (
          <div className="fixed right-0 bottom-0 h-full bg-black w-[300px]">
            <div className="flex justify-end p-12">
              <button onClick={() => setIsMenuToggled(!isMenuToggled)}>
                <img alt="close-icon" src="../assets/close-icon.svg" />
              </button>
            </div>

            {/* menu items */}
            <div
              className="flex flex-col gap-10 ml-[13%] text-2xl text-deep-blue transition duration-300"
              onClick={() => setIsMenuToggled(!isMenuToggled)}
            >
              <Link to="/">
                <div className="flex flex-row justify-center items-center hover:text-neonGreen transition duration-500">
                  <Icon
                    icon="eva:arrow-ios-back-fill"
                    className="w-[40px] h-[30px]"
                  />
                  <p>back to homepage</p>
                </div>
              </Link>
              <Link
                to="/about"
                className={`${
                  props.selectedPage === "/about"
                    ? "text-neonGreen border-b-2 border-l-2 rounded-[8px] px-5 border-green"
                    : ""
                } 
                        hover:text-neonGreen transition duration-500`}
              >
                About
              </Link>
              <Link
                to="/blog"
                className={`${
                  props.selectedPage === "/anotherPage"
                    ? "text-neonGreen border-b-2 border-l-2 rounded-[8px] px-5 border-green"
                    : ""
                } 
                        hover:text-neonGreen transition duration-500`}
              >
                Blog
              </Link>
            </div>
          </div>
        )}
      </div>
    </nav>
  );
};

export default NavbarRoute;
