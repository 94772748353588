import { FunctionComponent } from "react";
import { useEffect } from "react";
import useMediaQuery from "../../hooks/useMediaQuery";
import Navbar from "../../components/Navbar";
import Footer from "../Footer";
import Course from "./Course";
import SocialMediaTruLanguage from "../../components/SocialMediaTruLanguage";

export interface IRegisterCourse {}

const RegisterCourse: FunctionComponent<IRegisterCourse> = () => {
  const isAboveMediumScreens = useMediaQuery("(min-width: 1060px)");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div
      className={`app bg-deep-blue ${isAboveMediumScreens ? "" : "!bg-none"}`}
    >
      <Navbar />
      <div
        className={`w-5/6 mx-auto  ${
          isAboveMediumScreens ? "h-[100vh]" : "h-[150vh]"
        }`}
      >
        <Course /> <SocialMediaTruLanguage />
      </div>
      <div className="!mt-[15em]">
        <Footer />
      </div>
    </div>
  );
};

export default RegisterCourse;
