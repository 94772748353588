import { FunctionComponent } from "react";
import useMediaQuery from "../hooks/useMediaQuery";
import { motion } from "framer-motion";

const Services: FunctionComponent = () => {
  const isAboveMediumScreens = useMediaQuery("(min-width: 1060px)");

  return (
    <section id="services" className="pt-10 pb-24">
      {/* HEADER AND IMAGE SECTION */}
      <div className="md:flex md:justify-between md:gap-16 mt-32">
        <motion.div
          className="md:w-1/3"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, x: -50 },
            visible: { opacity: 1, x: 0 },
          }}
        >
          <p className="font-poppins font-semibold text-4xl mb-5">
            <span className="text-white">SERVICES</span>
          </p>
          <p className="mt-10 mb-7 font-poppins">
            Unlocking Excellence: From Salesforce Consulting to ICT Sector
            Recruitment, Software Development, and Design, our comprehensive
            suite of services empowers businesses. With a focus on tailored
            solutions, performance management, and staff training, we drive
            growth and success
          </p>
        </motion.div>

        <div className="mt-16 md:mt-0">
          {isAboveMediumScreens ? (
            <div
              className="relative z-0 ml-20 before:absolute before:-top-10 before:-left-10 
                before:w-full before:h-full before:border-2 before:border-blue before:z-[-1] before:border-[#61DB86] before:rounded-[23px]"
            >
              <img
                alt="services"
                className="z-10 rounded-[23px]"
                src="assets/skills-image.png"
              />
            </div>
          ) : (
            <img
              alt="services"
              className="z-10 rounded-[23px]"
              src="assets/skills-image.png"
            />
          )}
        </div>
      </div>

      {/* services */}
      <div className="md:flex md:justify-between mt-16 gap-20">
        {/* Salesforce */}
        <motion.div
          className="md:w-1/3 mt-10"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0 },
          }}
        >
          <div className="relative h-32">
            <div className="z-10 sticky">
              <p className="font-poppins font-semibold text-3xl mt-3">
                Salesforce Consulting
              </p>
            </div>
            <div className="w-1/2 md:w-3/4 h-32 bg-green  absolute right-0 top-0 z-1" />
          </div>
          <p className="mt-5 font-poppins">
            Salesforce consulting services, our aim is to transform your
            business operations, empower your team, and drive exceptional
            customer experiences.
          </p>
        </motion.div>

        <motion.div
          className="md:w-1/3 mt-10"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ delay: 0.2, duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0 },
          }}
        >
          <div className="relative h-32">
            <div className="z-10 sticky">
              {/* <p className="font-poppins font-semibold text-5xl">02</p> */}
              <p className="font-poppins font-semibold text-3xl mt-3">
                Software Development and Design
              </p>
            </div>
            <div className="w-1/2 md:w-3/4 h-32 bg-neonGreen absolute right-0 top-0 z-1" />
          </div>
          <p className="mt-5 font-poppins">
            By our Software Development and Design solutions we aim to fulfils
            our clients' requirements and enhances their sales performance.
          </p>
        </motion.div>
        {/* IMAGINATIVE */}
        <motion.div
          className="md:w-1/3 mt-10"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ delay: 0.4, duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0 },
          }}
        >
          <div className="relative h-32">
            <div className="z-10 sticky">
              {/* <p className="font-poppins font-semibold text-5xl">03</p> */}
              <p className="font-poppins font-semibold text-3xl mt-3">
                Digital Marketing
              </p>
            </div>
            <div className="w-1/2 md:w-3/4 h-32 bg-yellow absolute right-0 top-0 z-1" />
          </div>
          <p className="mt-5 font-poppins">
            Our focus is on tapping into the latest social media trends and
            using the newest technology to create genuine, impactful connections
            with your audience and skyrocket the sales.
          </p>
        </motion.div>
        <motion.div
          className="md:w-1/3 mt-10"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ delay: 0.4, duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0 },
          }}
        >
          <div className="relative h-32">
            <div className="z-10 sticky">
              {/* <p className="font-poppins font-semibold text-5xl">03</p> */}
              <p className="font-poppins font-semibold text-3xl mt-3">
                Performance Management and Staff Training
              </p>
            </div>
            <div className="w-1/2 md:w-3/4 h-32 bg-purple absolute right-0 top-0 z-1" />
          </div>
          <p className="mt-5 font-poppins">
            Dedicated to cultivate your team's capabilities. Through customised
            training, we empower your staff to reach their full potential, adapt
            to challenges, and drive remarkable results.
          </p>
        </motion.div>
      </div>
    </section>
  );
};

export default Services;
