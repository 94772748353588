import { FunctionComponent } from "react";
import { useEffect } from "react";
import Navbar from "../../components/Navbar";
import Landing from "../../pages/homePage/HomePage";
import Services from "../../pages/Services";
import Footer from "../../pages/Footer";
import Academy from "../Academy";
import UpcomingProjects from "../UpcomingProjects";
// import Partners from "../../Partners";

export interface IAppMain {}

const AppMain: FunctionComponent<IAppMain> = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={`app `}>
      <Navbar />
      <div className="w-5/6 mx-auto md:h-full">
        <Landing />
      </div>

      <div className="w-5/6 mx-auto md:h-full">
        <Services />
      </div>

      <div className="w-5/6 mx-auto md:h-full">
        <Academy />
      </div>

      <div className="w-5/6 mx-auto md:h-full">
        <UpcomingProjects />
      </div>

      {/* <div className="w-5/6 mx-auto h-[100vh]">
        <Partners />
      </div> */}
      <Footer />
    </div>
  );
};

export default AppMain;
