import LineGradient from "../../components/LineGardient";
import { motion } from "framer-motion";
import Person from "./Person";

const Testimonials = () => {
  return (
    <section id="team" className="pt-32 pb-16  transition duration-500">
      {/* HEADING */}
      <motion.div
        className="md:w-1/3 text-center md:text-left"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.5 }}
        transition={{ duration: 0.5 }}
        variants={{
          hidden: { opacity: 0, x: -50 },
          visible: { opacity: 1, x: 0 },
        }}
      >
        <p className="font-poppins font-semibold text-4xl mb-5">MEMBERS</p>
        <LineGradient width="mx-auto w-full" />
      </motion.div>

      {/* Team */}
      <div className="md:flex md:justify-between gap-8 ">
        <Person
          imgSrc="../assets/shpendi.jpeg"
          name="Shpend Mehmeti"
          role="Founder"
          description=""
          delay={0}
        />
        <Person
          imgSrc="../assets/raba.jpeg"
          name="Raba Mehmeti"
          role="Co-founder and Director"
          description=""
          delay={0}
          // background={"yellow"}
        />
        <Person
          imgSrc="../assets/shabani.jpeg"
          name="Shaban Shabani"
          role="Co-founder and CTO"
          description=""
          delay={0}
          // background={"neonGreen"}
        />
      </div>
      <div className="md:flex md:justify-between gap-8">
        <Person
          imgSrc="../assets/dardi.jpeg"
          name="Dardan Ahmeti"
          role="Partner & External Consultant"
          description="Founder at D.Reg - Frankfurt"
          delay={0}
        />

        <Person
          imgSrc="../assets/albesion.jpeg"
          name="Albeson Shabani"
          role="UI/UX Designer"
          description=""
          delay={0}
          // background={"neonGreen"}
        />

        <Person
          imgSrc="../assets/genti.jpeg"
          name="Gent Paci"
          role="Front End Developer"
          description=""
          delay={0}
          // background={"neonGreen"}
        />
      </div>
      <div className="md:flex md:justify-between gap-8">
        <Person
          imgSrc="../assets/rrezja.jpeg"
          name="Rrezarta Mehmeti"
          role="Social Media"
          description=""
          delay={0}
        />
        <Person
          imgSrc="../assets/liridon.jpeg"
          name="Liridon Olluri"
          role="Software Developer"
          description=""
          delay={0}
        />
        {/* <div className="w-[32%]"></div> */}
        {/* <div className="flex  w-[55%]"></div> */}
      </div>
    </section>
  );
};

export default Testimonials;
