import { FunctionComponent, useState } from "react";
import { useParams } from "react-router-dom";
import posts from "./post.json";
import NavbarRoute from "../components/NavbarRoute";
import Footer from "./Footer";

export interface IPost {}

const Post: FunctionComponent<IPost> = () => {
  const [selectedPage, setSelectedPage] = useState("/blog");
  const params = useParams();
  const postRead = posts[Number(params.id) - 1];
  const isTopOfPage = true

  return (
    <div className="app bg-deep-blue">
      <NavbarRoute
        isTopOfPage={isTopOfPage}
        selectedPage={selectedPage}
        setSelectedPage={setSelectedPage}
        page={selectedPage}
      />
      <div className="w-5/6 mx-auto md:h-full">
        <div id="services" className="pt-10 pb-24">
          <div className="md:flex md:justify-between md:gap-16 mt-52">
            <div className="flex flex-col justify-center items-center w-full">
              <img src={postRead?.image} className="w-[400px] h-[350px]" alt="img_log"/>
              <h1 className="font-bold text-6xl">{postRead?.title}</h1>
              <div className="mt-5">
                <p className="text-xl">{postRead?.description}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Post;
