import { FunctionComponent } from "react";

const SocialMediaTruLanguage: FunctionComponent = () => {
  return (
    <div className="flex justify-center md:justify-start my-10 gap-7 items-center">
      <a
        className="hover:opacity-50 transition duration-500 cursor-pointer"
        href="https://www.linkedin.com/company/trukosovo/"
        target="_blank"
        rel="noreferrer"
      >
        <img alt="linkedin-link" src="../assets/linkedin.png" />
      </a>
      <a
        className="hover:opacity-50 transition duration-500 cursor-pointer"
        href="https://www.tiktok.com/@tru.language?_t=8gD9cQtYVl8&_r=1"
        target="_blank"
        rel="noreferrer"
      >
        <img
          alt="tiktok-link"
          src="../assets/tiktok.jpg"
          className="w-[30px] border border-white rounded-[23px]"
        />
      </a>
      <a
        className="hover:opacity-50 transition duration-500 cursor-pointer"
        href="https://www.facebook.com/profile.php?id=61551131281485&mibextid=LQQJ4d"
        target="_blank"
        rel="noreferrer"
      >
        <img alt="facebook-link" src="../assets/facebook.png" />
      </a>
      <a
        className="hover:opacity-50 transition duration-500 cursor-pointer"
        href="https://instagram.com/trulanguage?igshid=MzRlODBiNWFlZA=="
        target="_blank"
        rel="noreferrer"
      >
        <img alt="instagram-link" src="../assets/instagram.png" />
      </a>
    </div>
  );
};

export default SocialMediaTruLanguage;
