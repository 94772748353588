import { FunctionComponent } from "react";
import { useEffect } from "react";
import Navbar from "../../components/Navbar";
import Footer from "../Footer";
import Service from "./Service";

export interface IService {}

const Services: FunctionComponent<IService> = () => {

  useEffect(() => {

    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="app bg-deep-blue">
      <Navbar />
      <div className="w-5/6 mx-auto md:h-[130vh]">
        <Service />
      </div>

      <Footer />
    </div>
  );
};

export default Services;
