import { FunctionComponent } from "react";
import useMediaQuery from "../hooks/useMediaQuery";
import { motion } from "framer-motion";
import FAQItem from "../components/ItemFAQ";

const Academy: FunctionComponent = () => {
  const isAboveMediumScreens = useMediaQuery("(min-width: 1060px)");

  return (
    <section id="services" className="pt-10 pb-24">
      <div className="md:flex md:justify-between md:gap-16 mt-32">
        <div className="mt-16 md:mt-0">
          {isAboveMediumScreens ? (
            <div
              className="relative z-0 ml-20 before:absolute before:-top-10 before:-left-10
                before:w-full before:h-full before:border-2 before:border-blue before:z-[-1] before:border-[#765CE5] before:rounded-[23px]"
            >
              <img
                alt="services"
                className="z-10 rounded-[23px]"
                src="assets/cnt.jpeg"
              />
            </div>
          ) : (
            <img
              alt="services"
              className="z-10 rounded-[23px]"
              src="assets/cnt.jpeg"
            />
          )}
        </div>
        <motion.div
          className="md:w-1/3"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, x: -50 },
            visible: { opacity: 1, x: 0 },
          }}
        >
          <p className="font-poppins font-semibold text-4xl mb-5">
            <span className="text-white">ACADEMY</span>
          </p>
          <p className="mt-2 text-lg font-poppins mb-3">
            Build Relevant Skills throughout our academies, develop skills that
            demanded by job market. Explore the Best Learning Practices and
            effective strategies for enhancing memory and productivity. Learn
            from Professionals and achieve your goals with the guidance of
            experienced professionals.
          </p>
          <ol>
            {/* <li className="mt-10 text-lg font-poppins rounded-[23px] border border-purple px-4 py-2 mb-2 hover:bg-green hover:cursor-pointer">
              Meta Learning - Learning how to Learn
            </li> */}
            {/* <li className="font-poppins text-lg  rounded-[23px] border border-purple px-4 py-2  mb-2 hover:bg-yellow hover:cursor-pointer">
              Salesforce Academy
            </li> */}
            {/* <li className="font-poppins text-lg rounded-[23px] border border-purple px-4 py-2 hover:bg-purple hover:cursor-pointer">
              <span className="text-neonGreen font-bold">TRU</span> Language
            </li> */}
            <li className="font-poppins text-lg rounded-[23px] border border-purple px-4 py-2 hover:bg-purple hover:cursor-pointer">
              Salesforce Academy
            </li>
            <FAQItem
              question={"Learning how to Learn"}
              answer={
                "Memory Techniques and Strategies <br/> Study Tips  <br/>Productivity and Time Management  <br/>Brain Health and Cognitive Enhancement  <br/>Reading Comprehension and Speed Reading Techniques"
              }
            />
            <FAQItem
              question={"TRU Language"}
              answer={
                "Replacing the books and notebooks with web app application and AI"
              }
            />

            {/* <FAQItem
              question={"Salesforce Academy"}
              answer={
                "Replacing traditional learning with web app application and AI"
              }
            /> */}
          </ol>
        </motion.div>
      </div>
    </section>
  );
};

export default Academy;
