import { motion } from "framer-motion";
import { FunctionComponent, useEffect } from "react";
import useMediaQuery from "../../hooks/useMediaQuery";

export interface IService {}

const Service: FunctionComponent<IService> = (props) => {
  const isAboveMediumScreens = useMediaQuery("(min-width: 1060px)");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <section id="team" className="pt-32 pb-16  transition duration-500">
      <motion.div
        className="md:w-full text-center md:text-left"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.5 }}
        transition={{ duration: 0.5 }}
        variants={{
          hidden: { opacity: 0, x: -50 },
          visible: { opacity: 1, x: 0 },
        }}
      >
        <p className="font-poppins font-semibold text-4xl text-neonGreen mb-5 border-b-2 border-neonGreen">
          Our Services
        </p>

        <div
          className={`flex ${
            !isAboveMediumScreens ? "flex-col" : "flex-row"
          }  justify-between`}
        >
          <div
            className={`flex flex-col border-8 border-double border-neonGreen rounded-[23px] px-10 py-10 ${
              isAboveMediumScreens ? "w-[45%] " : "mb-5"
            } h-[500px] items-center text-center hover:bg-purple`}
          >
            <img
              src="/assets/salesforce.svg"
              className="w-[220px] "
              alt="img_lg"
            />
            <p className="font-poppins font-semibold text-3xl mb-5">
              Salesforce Consulting
            </p>
            <p>
              Salesforce consulting services, our aim is to transform your
              business operations, empower your team, and drive exceptional
              customer experiences.
            </p>
            <button className="bg-yellow w-[220px] h-[50px] mt-5 rounded-[23px] font-bold font-poppins">
              Read more..
            </button>
          </div>
          <div
            className={`flex flex-col border-8 border-double border-neonGreen rounded-[23px] px-10 py-10 ${
              isAboveMediumScreens ? "w-[45%]" : ""
            } h-[500px] items-center text-center hover:bg-purple`}
          >
            <img
              src="/assets/software.png"
              className="w-[180px] h-[180px]"
              alt="img_lg"
            />
            <p className="font-poppins font-semibold text-3xl mb-5">
              Software Development and Design
            </p>
            <p>
              By our Software Development and Design solutions we aim to fulfils
              our clients' requirements and enhances their sales performance.
            </p>
            <button className="bg-yellow w-[220px] h-[50px] mt-5 rounded-[23px] font-bold font-poppins">
              Read more..
            </button>
          </div>
        </div>

        <div
          className={`flex ${
            !isAboveMediumScreens ? "flex-col" : "flex-row"
          }  justify-between mt-24`}
        >
          <div
            className={`flex flex-col border-8 border-double border-neonGreen rounded-[23px] px-10 py-10 ${
              isAboveMediumScreens ? "w-[45%]" : "mb-5"
            } h-[500px] items-center text-center hover:bg-purple`}
          >
            <img
              src="/assets/recruitment2.png"
              className="w-[180px] h-[180px]"
              alt="img_lg"
            />
            <p className="font-poppins font-semibold text-3xl mb-5">
              Digital Marketing
            </p>
            <p>
              Our focus is on tapping into the latest social media trends and
              using the newest technology to create genuine, impactful
              connections with your audience and skyrocket the sales.
            </p>
            <button className="bg-yellow w-[220px] h-[50px] mt-5 rounded-[23px] font-bold font-poppins">
              Read more..
            </button>
          </div>
          <div
            className={`flex flex-col border-8 border-double border-neonGreen rounded-[23px] px-10 py-10 ${
              isAboveMediumScreens ? "w-[45%]" : ""
            } h-[500px] items-center text-center hover:bg-purple`}
          >
            <img
              src="/assets/training2.png"
              className="w-[180px] h-[180px]"
              alt="img_lg"
            />
            <p className="font-poppins font-semibold text-3xl mb-5">
              Performance Management and Staff Training
            </p>
            <p>
              Dedicated to cultivate your team's capabilities. Through
              customised training, we empower your staff to reach their full
              potential, adapt to challenges, and drive remarkable results.
            </p>
            <button className="bg-yellow w-[220px] h-[50px] mt-5 rounded-[23px] font-bold font-poppins">
              Read more..
            </button>
          </div>
        </div>
      </motion.div>
    </section>
  );
};

export default Service;
