import { FunctionComponent } from "react";
import { useEffect } from "react";
import Navbar from "../../components/Navbar";
import Team from "./Team";
// import Contact from "../Contact";
import Footer from "../Footer";

export interface ITeamMain {}

const TeamMain: FunctionComponent<ITeamMain> = () => {

  useEffect(() => {

    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="app bg-deep-blue">
      <Navbar />
      <div className="w-5/6 mx-auto md:h-[140vh]">
        <Team />
      </div>
      <div className="w-5/6 mx-auto md:h-full">
        {/* <Contact /> */}
      </div>
      <Footer />
    </div>
  );
};

export default TeamMain;
