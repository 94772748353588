import { FunctionComponent } from "react";
import Navbar from "../components/Navbar";
import Footer from "./Footer";
import useMediaQuery from "../hooks/useMediaQuery";
import posts from "./post.json";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

export interface IAnotherPage {}

const AnotherPage: FunctionComponent<IAnotherPage> = () => {
  const isAboveMediumScreens = useMediaQuery("(min-width: 1060px)");

  return (
    <div className="app bg-deep-blue">
      <Navbar />
      <div className="w-5/6 mx-auto md:h-full">
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, x: -50 },
            visible: { opacity: 1, x: 0 },
          }}
        >
          <div className="pt-10 pb-24">
            <div className="md:flex md:justify-between md:gap-16 mt-52">
              <div
                className={
                  isAboveMediumScreens ? "flex flex-row" : "flex flex-col"
                }
              >
                {posts.map((item) => (
                  <Link to={`/post/${item.postId}`}>
                    <div
                      className={
                        isAboveMediumScreens
                          ? "flex flex-col justify-center items-center max-w-[400px] max-h-[320px]"
                          : " flex flex-col justify-center items-center"
                      }
                    >
                      <div className="flex flex-col items-center px-4 py-4 mt-4 hover:border-2 hover:border-green hover:rounded-[13px]">
                        <img
                          src="/assets/truMain.png"
                          className="w-full rounded-[23px]"
                          alt="img_lg"
                        />
                        <h2 className="text-3xl font-poppins font-bold mt-4">
                          {item.title}
                        </h2>
                      </div>
                    </div>
                  </Link>
                ))}
              </div>
            </div>
          </div>
        </motion.div>
      </div>
      <Footer />
    </div>
  );
};

export default AnotherPage;
