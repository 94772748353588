import React, { useState, FunctionComponent, useEffect } from "react";
import useMediaQuery from "../hooks/useMediaQuery";
import { Link, useLocation } from "react-router-dom";
import { navItems } from "./nav-items";

export interface INavbar {}

const Navbar: FunctionComponent<INavbar> = () => {
  const [isMenuToggled, setIsMenuToggled] = useState(false);
  const isAboveSmallScreens = useMediaQuery("(min-width: 768px)");
  const [isTopOfPage, setIsTopOfPage] = useState(true);
  const navbarBackground = isTopOfPage ? "" : "bg-black";
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY === 0) {
        setIsTopOfPage(true);
      }
      if (window.scrollY !== 0) setIsTopOfPage(false);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <nav
      className={`${navbarBackground} z-40 w-full fixed top-0 py-6 border-b border-green`}
    >
      <div className="flex flex-row items-center justify-between mx-auto w-5/6">
        <Link to={"/"}>
          <div className="max-h-[40px]">
            <img
              alt="logo"
              className="w-[150px] -mt-[4em]"
              src="../assets/logo.png"
            />
          </div>
        </Link>
        {/* desktop menu */}
        {isAboveSmallScreens ? (
          <div className={`flex justify-between gap-5 font-poppins text-xl`}>
            {navItems.map((item) => (
              <Link
                to={item.href}
                className={`${
                  location.pathname === item.href
                    ? "text-neonGreen border-2  px-5  rounded-[8px] flex justify-center  border-green font-semibold"
                    : item.href === ""
                    ? "text-opacity-50  text-white hover:text-opacity-50 hover:text-white"
                    : ""
                }  
                        hover:text-neonGreen transition duration-500 `}
              >
                <p>{item.label}</p>
              </Link>
            ))}
          </div>
        ) : (
          <button
            className="rounded-full bg-green p-2 transition-all"
            onClick={() => setIsMenuToggled(!isMenuToggled)}
          >
            <img alt="menu-icon" src="../assets/menu-icon.svg" />
          </button>
        )}
        {/* mobile menu */}

        {!isAboveSmallScreens && isMenuToggled && (
          <div className="fixed right-0 bottom-0 h-full border-l border-green bg-black w-[300px] transition-opacity duration-1000 ease-out opacity-1000">
            <div className="flex justify-end p-12 ">
              <button
                onClick={() => setIsMenuToggled(!isMenuToggled)}
                className="transition-all "
              >
                <img alt="close-icon" src="../assets/close-icon.svg" />
              </button>
            </div>

            <div
              className="flex flex-col gap-10 ml-[13%] text-2xl text-deep-blue"
              onClick={() => setIsMenuToggled(!isMenuToggled)}
            >
              {navItems.map((item) => (
                <Link
                  to={item.href}
                  className={`${
                    location.pathname === item.href
                      ? "text-neonGreen border-b-2 border-l-2 rounded-[8px] px-5 border-green"
                      : ""
                  }  
                      !hover:text-neonGreen transition duration-500`}
                >
                  {item.label}
                </Link>
              ))}
            </div>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
