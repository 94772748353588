import { FunctionComponent } from "react";
import { useEffect } from "react";
import Navbar from "../../components/Navbar";
import Product from "./Product";
import Footer from "../Footer";

export interface IProducts {}

const Products: FunctionComponent<IProducts> = () => {

  useEffect(() => {

    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="app bg-deep-blue">
      <Navbar />
      <div className="w-5/6 mx-auto md:h-[100%]]">
        <Product />
      </div>
 
      <Footer />
    </div>
  );
};

export default Products;
