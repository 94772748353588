import useMediaQuery from "../../hooks/useMediaQuery";
import { motion } from "framer-motion";

const Academy = () => {
  const isAboveMediumScreens = useMediaQuery("(min-width: 1060px)");

  return (
    <section id="services" className="pt-10 pb-24  md:gap-16 mt-32">
      <p className="text-5xl font-poppins font-bold">Products</p>
      <motion.div
        className={`w-full flex mt-10 ${
          isAboveMediumScreens ? "flex-row" : "flex-col text-center"
        } items-center !justify-around `}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.5 }}
        transition={{ duration: 0.5 }}
        variants={{
          hidden: { opacity: 0, x: -50 },
          visible: { opacity: 1, x: 0 },
        }}
      >
        <div
          className={`flex w-[40%] ${
            isAboveMediumScreens ? "flex-row" : "flex-col"
          }   items-center`}
        >
          <img
            alt="services"
            className="z-10 w-[200px]"
            src="assets/logotransparent.png"
          />
          <p className="font-poppins font-semibold text-4xl mb-5 flex">
            <p className="text-4xl font-poppins font-bold z-10 text-center md:text-start text-yellow">
              tru
            </p>
            <p className="text-4xl font-poppins font-bold z-10 text-center md:text-start text-purple">
              modoro
            </p>
          </p>
        </div>

        <div className={`flex ${!isAboveMediumScreens ? "" : "w-[40%]"}`}>
          <p
            className={`mt-2 text-lg font-poppins ${
              !isAboveMediumScreens && "mx-12"
            }`}
          >
            boost and track your productivity with our web/mobile app
            appplication
          </p>
        </div>
      </motion.div>
      <div className="border-b-2 border-purple my-12"></div>
      {/* projects */}
      <p className="text-5xl font-poppins font-bold my-6 ">Projects</p>
      <motion.div
        className={`w-full flex mt-10 ${
          isAboveMediumScreens ? "flex-row" : "flex-col text-center"
        } items-center !justify-around `}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.5 }}
        transition={{ duration: 0.5 }}
        variants={{
          hidden: { opacity: 0, x: -50 },
          visible: { opacity: 1, x: 0 },
        }}
      >
        <div
          className={`flex w-[40%] ${
            isAboveMediumScreens ? "flex-row" : "flex-col"
          }   items-center`}
        >
          <img
            alt="services"
            className="z-10 w-[200px]"
            src="assets/autorks.png"
          />
          <p className="font-poppins font-semibold text-4xl mb-5 flex">
            <p className="text-4xl font-poppins font-bold z-10 text-center md:text-start text-[#FA4616]">
              auto
            </p>
            <p className="text-4xl font-poppins font-bold z-10 text-center md:text-start text-white">
              rks
            </p>
            
          </p>
          
        </div>

        <div className={`flex ${!isAboveMediumScreens ? "" : "w-[40%]"}`}>
         <div className="flex flex-col">
         <p
            className={`mt-2 text-lg font-poppins ${
              !isAboveMediumScreens && "mx-12"
            }`}
          >
               AutoRKS is the first online marketplace dedicated to cars.
          </p>
          <p className=" font-poppins"><a href="https://autorks.com/" className="font-poppins cursor-pointer hover:text-purple text-xl">www.autorks.com</a></p>
         </div>

        </div>
      </motion.div>
      {/* <div className="border-b-2 border-dashed border-purple my-12"></div> */}
      <motion.div
        className={`w-full flex mt-32 ${
          isAboveMediumScreens ? "flex-row" : "flex-col text-center"
        } items-center !justify-around `}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.5 }}
        transition={{ duration: 0.5 }}
        variants={{
          hidden: { opacity: 0, x: -50 },
          visible: { opacity: 1, x: 0 },
        }}
      >
        <div
          className={`flex w-[40%] ${
            isAboveMediumScreens ? "flex-row" : "flex-col"
          }   items-center`}
        >
          <img
            alt="services"
            className="z-10 w-[250px] bg-white"
            src="https://albinadyla.com/cdn/shop/files/Modern_Minimal_Letter_M_Photographer_Initials_Logo_5_600x.png?v=1643914215"
          />
          <p className="font-poppins font-semibold text-4xl mb-5 flex">
        
          </p>
        </div>

        
        <div className={`flex flex-col ${!isAboveMediumScreens ? "" : "w-[40%]"}`}>
          <p
            className={`mt-2 text-lg font-poppins ${
              !isAboveMediumScreens && "mx-12"
            }`}
          >
               Albina Dyla is a large company founded in Kosovo. This company provides high-quality dresses to more than 89 countries internationally. Since Kosovo is still a developing country, Albina Dyla’s journey to where it is now was not that smooth.
          </p>
          <p className="font-poppins"><a href="https://albinadyla.com/" className="font-poppins cursor-pointer hover:text-purple text-xl">www.albinadyla.com</a></p>
        </div>
      </motion.div>
      <motion.div
        className={`w-full flex mt-32 ${
          isAboveMediumScreens ? "flex-row" : "flex-col text-center"
        } items-center !justify-around `}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.5 }}
        transition={{ duration: 0.5 }}
        variants={{
          hidden: { opacity: 0, x: -50 },
          visible: { opacity: 1, x: 0 },
        }}
      >
        <div
          className={`flex w-[40%] ${
            isAboveMediumScreens ? "flex-row" : "flex-col"
          }   items-center`}
        >
          <img
            alt="services"
            className="z-10 w-[250px] bg-white"
            src="https://dodonaavdiu.com/cdn/shop/files/LOGO_3.png?v=1686312295"
          />
          <p className="font-poppins font-semibold text-4xl mb-5 flex">
        
          </p>
        </div>

        
        <div className={`flex flex-col ${!isAboveMediumScreens ? "" : "w-[40%]"}`}>
          <p
            className={`mt-2 text-lg font-poppins ${
              !isAboveMediumScreens && "mx-12"
            }`}
          >
              Dodona Avdiu 
          </p>
          <p className="font-poppins"><a href="https://dodonaavdiu.com/" className="font-poppins cursor-pointer hover:text-purple text-xl">www.dodonaavdiu.com</a></p>
        </div>
      </motion.div>
      <motion.div
        className={`w-full flex mt-32 ${
          isAboveMediumScreens ? "flex-row" : "flex-col text-center"
        } items-center !justify-around `}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.5 }}
        transition={{ duration: 0.5 }}
        variants={{
          hidden: { opacity: 0, x: -50 },
          visible: { opacity: 1, x: 0 },
        }}
      >
        <div
          className={`flex w-[40%] ${
            isAboveMediumScreens ? "flex-row" : "flex-col"
          }   items-center`}
        >
          <img
            alt="services"
            className="z-10 w-[250px] bg-white"
            src="/assets/mina.jpeg"
          />
          <p className="font-poppins font-semibold text-4xl mb-5 flex">
        
          </p>
        </div>

        
        <div className={`flex flex-col ${!isAboveMediumScreens ? "" : "w-[40%]"}`}>
          <p
            className={`mt-2 text-lg font-poppins ${
              !isAboveMediumScreens && "mx-12"
            }`}
          >
              Mina Shop
          </p>
          <p className="font-poppins"><a href="https://minna.shop/" className="font-poppins cursor-pointer hover:text-purple text-xl">www.minna.shop</a></p>
        </div>
      </motion.div>
      <motion.div
        className={`w-full flex mt-32 ${
          isAboveMediumScreens ? "flex-row" : "flex-col text-center"
        } items-center !justify-around `}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.5 }}
        transition={{ duration: 0.5 }}
        variants={{
          hidden: { opacity: 0, x: -50 },
          visible: { opacity: 1, x: 0 },
        }}
      >
        <div
          className={`flex w-[40%] ${
            isAboveMediumScreens ? "flex-row" : "flex-col"
          }   items-center`}
        >
          <img
            alt="services"
            className="z-10 w-[250px] bg-white"
            src="https://noresylejmani.com/wp-content/uploads/2023/02/IMG-7691-1.png"
          />
          <p className="font-poppins font-semibold text-4xl mb-5 flex">
        
          </p>
        </div>

        
        <div className={`flex flex-col ${!isAboveMediumScreens ? "" : "w-[40%]"}`}>
          <p
            className={`mt-2 text-lg font-poppins ${
              !isAboveMediumScreens && "mx-12"
            }`}
          >
              Nore Sylejmani
          </p>
          <p className="font-poppins"><a href="https://noresylejmani.com/" className="font-poppins cursor-pointer hover:text-purple text-xl">www.noresylejmani.com</a></p>
        </div>
      </motion.div>
    </section>
  );
};

export default Academy;
