import { useLocation } from "react-router-dom";
import SocialMediaIcons from "../components/SocialMediaIcons";

const Footer = () => {
  const location = useLocation();
  console.log(location.pathname, "LLL");
  return (
    <footer className="h-52">
      <div className="w-10/12 mx-auto">
        {location.pathname === "/register" ? "" : <SocialMediaIcons />}

        <div className="md:flex justify-center md:justify-between text-center ">
          <p className="font-playfair font-semibold text-xl text-yellow">TRU</p>
          <p className="font-playfair text-md text-yellow">
            ©2023. All Rights Reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
