import React from 'react';
import { motion, Variants } from 'framer-motion';

interface PersonProps {
  imgSrc: string;
  name: string;
  role?: string;
  description: string;
  delay: number;
  background?: string;
}

const Person: React.FC<PersonProps> = ({ imgSrc, name, role, description, delay,background }) => {
  const animationVariants: Variants = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: { opacity: 1, scale: 1 },
  };

  return (
    <motion.div
      className={`mx-auto relative bg-${background} max-w-[400px] max-h-[350px] flex flex-col justify-end p-16 mt-5 items-center rounded-[23px]`}
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true, amount: 0.5 }}
      transition={{ delay: delay, duration: 0.6 }}
      variants={animationVariants}
    >
      <img src={imgSrc} alt={name} className="rounded-full w-[180px]  h-[180px] object-cover	"/>
      <p className="text-3xl text-center text-purple font-bold mb-5 w-[300px]">{name}</p>
      <p className="text-center text-2xl text-purple font-bold mb-2 w-[400px]">{role}</p>
      <p className="text-center text-base text-purple font-bold absolute bottom-[39px]">{description}</p>
    </motion.div>
  );
};

export default Person;
