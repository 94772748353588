import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import AppMain from "./pages/homePage/AppMain";
import BlogPage from "./pages/BlogPage";
// import CoomingSoon from "./pages/ComingSoon";
// import About from "./pages/teams/About";
import Products from "./pages/products/Products";
import Post from "./pages/Post";
import TeamMain from "./pages/teams/TeamMain";
import Services from "./pages//services/Services";
import RegisterCourse from "./pages/course/Register";

function App() {
  return (
    <Router>
      <Routes>
        {/* <Route  path="/" element={<CoomingSoon/>}/> */}
        <Route path="/" element={<AppMain />} />
        <Route path="/blog" element={<BlogPage />} />
        <Route path="/about" element={<TeamMain />} />
        <Route path="/products" element={<Products />} />
        <Route path="/services" element={<Services />} />
        <Route path="post/:id" element={<Post />} />

        {/* register course */}
        <Route path="/register" element={<RegisterCourse />} />
      </Routes>
    </Router>
  );
}

export default App;
