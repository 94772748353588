export interface INavLink {
  label: string;
  href: string;
}

export const navItems: Array<INavLink> = [
  {
    label: "Home",
    href: "/",
  },

  {
    label: "Our Products",
    href: "/products",
  },
  {
    label: "About Tru",
    href: "/about",
  },
  {
    label: "Services",
    // href: "/services",
    href: "",
  },
  {
    label: "Academy",
    href: "",
  },
  {
    label: "Register",
    href: "/register",
  },
];
