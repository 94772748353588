import { FunctionComponent } from "react";

const SocialMediaIcons: FunctionComponent = () => {
  return (
    <div className="flex justify-center md:justify-start my-20 gap-7 items-center">
      <a
        className="hover:opacity-50 transition duration-500"
        href="https://www.linkedin.com/company/trukosovo/"
        target="_blank"
        rel="noreferrer"
      >
        <img alt="linkedin-link" src="../assets/linkedin.png" />
      </a>
      <a
        className="hover:opacity-50 transition duration-500"
        // href=""
        // target="_blank"
        // rel="noreferrer"
      >
        <img alt="twitter-link" src="../assets/twitter.png" />
      </a>
      <a
        className="hover:opacity-50 transition duration-500"
        // href=""
        // target="_blank"
        // rel="noreferrer"
      >
        <img alt="facebook-link" src="../assets/facebook.png" />
      </a>
      <a
        className="hover:opacity-50 transition duration-500"
        // href=""
        // target="_blank"
        // rel="noreferrer"
      >
        <img alt="instagram-link" src="../assets/instagram.png" />
      </a>
    </div>
  );
};

export default SocialMediaIcons;
