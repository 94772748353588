import { motion } from "framer-motion";
import LineGradient from "../../components/LineGardient";
import { useEffect, useState } from "react";
import ReactLoading from "react-loading";
import axios from "axios";
import useMediaQuery from "../../hooks/useMediaQuery";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { format } from "date-fns";

export interface ICourse {}

const Course = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  // const [numberPhone, setNumberPhone] = useState("");
  const [komuna, setKomuna] = useState("");
  const [niveli, setNiveli] = useState("");
  const [hasSubmit, setHasSubmit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [hasLoading, setHasLoading] = useState(false);
  const [mesoj, setMesoj] = useState("");
  const [prezenca, setPrezenca] = useState("");
  const isAboveMediumScreens = useMediaQuery("(min-width: 1060px)");

  const apiUrl = process.env.REACT_APP_GOOGLE_SHEET;

  const [numberPhone, setNumberPhone] = useState<string>("");
  const [hasPrefix, setHasPrefix] = useState<boolean>(false);
  const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value;

    value = value.replace(/\D/g, "");

    if (value.length > 15) {
      value = value.slice(0, 15);
    }

    if (value.length > 0 && !hasPrefix) {
      // value = "+383 " + value;
      setHasPrefix(true);
    } else if (value.length === 0) {
      setHasPrefix(false);
    }

    setNumberPhone(value);
  };

  useEffect(() => {
    if (isLoading) {
      setHasLoading(true);
    } else {
      setHasLoading(false);
    }
  }, [isLoading, hasLoading]);
  async function submit() {
    try {
      const response = await axios.get(apiUrl!);

      const existEmail = response.data.data.some(
        (item: any) => item.Email === email
      );

      if (
        email === "" ||
        firstName === "" ||
        lastName === "" ||
        numberPhone === "" ||
        komuna === "" ||
        niveli === "" ||
        mesoj === ""
      ) {
        console.log(
          email,
          firstName,
          lastName,
          numberPhone,
          komuna,
          niveli,
          mesoj,
          prezenca,
          "abc"
        );
        toast.error("Ju lutem plotësoni të gjitha hapësirat !");
        setHasLoading(false);
        setIsLoading(false);
      } else if (existEmail) {
        toast.error("Kjo email ekziston!!");
        setIsLoading(false);
      } else {
        await axios.post(apiUrl!, [
          [
            firstName,
            lastName,
            email,
            numberPhone,
            komuna,
            niveli,
            mesoj,
            prezenca,
            format(new Date(), "MM-dd-yyyy HH:mm"),
          ],
        ]);
        toast.info("Ju lutem, pritni një moment !");
        setHasSubmit(true);
        setTimeout(() => {
          setIsLoading(false);
        }, 3000);
      }
    } catch (error) {
      console.error("Error making the request:", error);
    }
  }

  return (
    <section id="register" className="pt-32 pb-16  transition duration-500">
      <motion.div
        className="md:w-1/3 text-center md:text-left"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.5 }}
        transition={{ duration: 0.5 }}
        variants={{
          hidden: { opacity: 0, x: -50 },
          visible: { opacity: 1, x: 0 },
        }}
      >
        <p className="font-poppins font-semibold text-4xl mb-5">
          Regjistrohu në kurs
        </p>
        <LineGradient width="mx-auto w-full" />
      </motion.div>

      <div className="mt-10">
        {!isAboveMediumScreens ? (
          <>
            <div className="flex flex-col justify-center items-center">
              <img
                alt="img"
                src="/assets/kurse-poster.png"
                className="max-w-[350px] max-h-[500px] mb-20"
              />
              {!hasSubmit ? (
                <div className="flex flex-col items-center justify-center">
                  <div className="flex flex-col">
                    <input
                      className="w-[300px] text-green rounded-[15px] border-none focus:!border-none font-semibold placeholder-opaque-black p-3 mb-2"
                      type="text"
                      placeholder="Emri"
                      onChange={(event) => {
                        setFirstName(event.target.value);
                      }}
                    />
                    <input
                      className="w-[300px] text-green rounded-[15px] border-none focus:!border-none font-semibold placeholder-opaque-black p-3"
                      type="text"
                      placeholder="Mbiemri"
                      onChange={(event) => {
                        setLastName(event.target.value);
                      }}
                    />
                  </div>
                  <div className="flex flex-col mt-5">
                    <input
                      className="w-[300px] text-green rounded-[15px] border-none focus:!border-none font-semibold placeholder-opaque-black p-3 mb-2"
                      type="email"
                      placeholder="Email"
                      onChange={(event) => {
                        setEmail(event.target.value);
                      }}
                      required
                    />
                    {/* <input
                      className="w-[300px] text-green rounded-[15px] border-none focus:!border-none font-semibold placeholder-opaque-black p-3 "
                      type="number"
                      placeholder="Nr.tel"
                      onChange={(event) => {
                        setNumberPhone(event.target.value);
                      }}
                      required
                      minLength={9}
                    /> */}
                    <input
                      className="w-[300px] text-green rounded-[15px] border-none focus:!border-none font-semibold placeholder-opaque-black p-3 "
                      type="text"
                      placeholder="049/044111222"
                      value={numberPhone}
                      onChange={handlePhoneChange}
                    />
                    {/* <p>formati i numrit</p> */}
                  </div>
                  <div className="flex flex-col mt-5">
                    <select
                      className="w-[300px] min-h-[48px] text-green rounded-[15px] border-none focus:!border-none font-semibold placeholder-opaque-black p-3 selection:rounded-[15px] mb-2"
                      onChange={(event) => {
                        setKomuna(event.target.value);
                      }}
                      required
                    >
                      <option value="" disabled selected>
                        Vendi më i afërt
                      </option>
                      <option value="prishtine">Prishtine</option>
                      <option value="ferizaj">Ferizaj</option>
                      <option value="vushtrri">Vushtrri</option>
                      <option value="mitrovice">Mitrovice</option>
                      <option value="prizren">Prizren</option>
                      <option value="pejë">Pejë</option>
                      <option value="gjilan">Gjilan</option>
                      <option value="gjakove">Gjakovë</option>

                      <option value="shqipëri">Shqipëri</option>
                      <option value="maqedoni">Maqedoni</option>
                      <option value="gjermani">Gjermani</option>
                      <option value="zvicer">Zvicër</option>
                    </select>

                    <select
                      className="w-[300px] min-h-[48px] text-green rounded-[15px] border-none focus:!border-none font-semibold placeholder-opaque-black p-3 selection:rounded-[15px]"
                      onChange={(event) => {
                        setNiveli(event.target.value);
                      }}
                      required
                    >
                      <option value="" disabled selected>
                        Niveli im i Gjuhës
                      </option>
                      <option className="!rounded-[15px]" value="0">
                        Nuk kam njohuri
                      </option>
                      <option value="A1">A1</option>
                      <option value="A2">A2</option>
                      <option value="B1">B1</option>
                      <option value="B2">B2</option>
                    </select>
                  </div>
                  <div className="flex flex-col mt-5">
                    <select
                      className="w-[300px] min-h-[48px] text-green rounded-[15px] border-none focus:!border-none font-semibold placeholder-opaque-black p-3 selection:rounded-[15px] mr-2"
                      onChange={(event) => {
                        setPrezenca(event.target.value);
                        console.log(event.target.value, "test prezenca");
                      }}
                      required
                    >
                      <option value="" disabled selected>
                        Prezenca
                      </option>
                      <option value="fizikisht">Fizikisht</option>
                      <option value="online">Online</option>
                    </select>
                    <select
                      className="w-[300px] min-h-[48px] text-green rounded-[15px] mt-2 border-none focus:!border-none font-semibold placeholder-opaque-black p-3 selection:rounded-[15px] mb-2"
                      onChange={(event) => {
                        setMesoj(event.target.value);
                      }}
                      required
                    >
                      <option value="" disabled selected>
                        Dua të mësoj për
                      </option>

                      <option value="turizem/jetes">
                        Turizëm/Jetesë në gjermani
                      </option>

                      <option value="call_center">Call Center</option>
                      <option value="mjekesi">Mjekësi</option>
                      <option value="biznes">Biznes</option>
                      <option value="ict">ICT</option>
                    </select>
                  </div>
                  <div className="flex justify-center">
                    <button
                      className="w-[300px] h-[50px] rounded-[15px] bg-purple mt-10 hover:bg-yellow"
                      onClick={() => {
                        setIsLoading(true);
                        submit();
                      }}
                    >
                      {isLoading ? (
                        <p className="font-poppins font-bold">Loading...</p>
                      ) : (
                        <p className="font-poppins font-bold">
                          Regjistrohu në{" "}
                          <span className="text-neonGreen">TRU Academy</span>
                        </p>
                      )}
                    </button>
                  </div>
                </div>
              ) : (
                <div className="flex justify-center items-center text-center">
                  {hasLoading ? (
                    <ReactLoading
                      type="spinningBubbles"
                      color="#765CE5"
                      height={300}
                      width={200}
                    />
                  ) : (
                    <p className="text-[40px] font-bold text-purple">
                      Regjistrimi u krye me sukses, do ju kontaktojmë së shpejti
                    </p>
                  )}
                </div>
              )}
            </div>
          </>
        ) : (
          <>
            {" "}
            <div className="flex flex-row">
              <img
                alt="img"
                src="/assets/kurse-poster.png"
                className="max-w-[500px] max-h-[700px] mr-20"
              />
              {!hasSubmit ? (
                <div className="flex flex-col">
                  <div className="flex flex-row">
                    <input
                      className="w-[300px] text-green rounded-[15px] border-none focus:!border-none font-semibold placeholder-opaque-black p-3 mr-2"
                      type="text"
                      placeholder="Emri"
                      onChange={(event) => {
                        setFirstName(event.target.value);
                      }}
                      required
                    />
                    <input
                      className="w-[300px] text-green rounded-[15px] border-none focus:!border-none font-semibold placeholder-opaque-black p-3"
                      type="text"
                      placeholder="Mbiemri"
                      onChange={(event) => {
                        setLastName(event.target.value);
                      }}
                      required
                    />
                  </div>
                  <div className="flex flex-row mt-5">
                    <input
                      className="w-[300px] text-green rounded-[15px] border-none focus:!border-none font-semibold placeholder-opaque-black p-3 mr-2"
                      type="email"
                      placeholder="Email"
                      onChange={(event) => {
                        setEmail(event.target.value);
                      }}
                      required
                    />
                    {/* <input
                      className="w-[300px] text-green rounded-[15px] border-none focus:!border-none font-semibold placeholder-opaque-black p-3 mr-2"
                      type="text"
                      placeholder="Nr. tel"
                      onChange={(event) => {
                        setNumberPhone(event.target.value);
                      }}
                      required
                      minLength={9}
                    /> */}
                    <input
                      className="w-[300px] text-green rounded-[15px] border-none focus:!border-none font-semibold placeholder-opaque-black p-3 mr-2"
                      type="text"
                      placeholder="049/044111222"
                      value={numberPhone}
                      onChange={handlePhoneChange}
                    />
                  </div>
                  <div className="flex flex-row mt-5">
                    <select
                      className="w-[300px] min-h-[48px] text-green rounded-[15px] border-none focus:!border-none font-semibold placeholder-opaque-black p-3 selection:rounded-[15px] mr-2"
                      onChange={(event) => {
                        setKomuna(event.target.value);
                      }}
                      required
                    >
                      <option value="" disabled selected>
                        Vendi më i afërt
                      </option>
                      <option value="prishtine">Prishtine</option>
                      <option value="ferizaj">Ferizaj</option>
                      <option value="vushtrri">Vushtrri</option>
                      <option value="mitrovice">Mitrovice</option>
                      <option value="prizren">Prizren</option>
                      <option value="pejë">Pejë</option>
                      <option value="gjilan">Gjilan</option>
                      <option value="gjakove">Gjakovë</option>

                      <option value="shqipëri">Shqipëri</option>
                      <option value="maqedoni">Maqedoni</option>
                      <option value="gjermani">Gjermani</option>
                      <option value="zvicer">Zvicër</option>
                    </select>

                    <select
                      className="w-[300px] min-h-[48px] text-green rounded-[15px] border-none focus:!border-none font-semibold placeholder-opaque-black p-3 selection:rounded-[15px]"
                      onChange={(event) => {
                        setNiveli(event.target.value);
                      }}
                      required
                    >
                      <option value="" disabled selected>
                        Niveli im i Gjuhës
                      </option>
                      <option className="!rounded-[15px]" value="0">
                        Nuk kam njohuri
                      </option>
                      <option value="A1">A1</option>
                      <option value="A2">A2</option>
                      <option value="B1">B1</option>
                      <option value="B2">B2</option>
                    </select>
                  </div>
                  <div className="flex flex-row mt-5">
                    <select
                      className="w-[300px] min-h-[48px] text-green rounded-[15px] border-none focus:!border-none font-semibold placeholder-opaque-black p-3 selection:rounded-[15px] mr-2"
                      onChange={(event) => {
                        setPrezenca(event.target.value);
                        console.log(event.target.value, "prezenca");
                      }}
                      required
                    >
                      <option value="" disabled selected>
                        Prezenca
                      </option>
                      <option value="fizikisht">Fizikisht</option>
                      <option value="online">Online</option>
                    </select>
                    <select
                      className="w-[300px] min-h-[48px] text-green rounded-[15px] border-none focus:!border-none font-semibold placeholder-opaque-black p-3 selection:rounded-[15px] mr-2"
                      onChange={(event) => {
                        setMesoj(event.target.value);
                      }}
                      required
                    >
                      <option value="" disabled selected>
                        Dua të mësoj për
                      </option>
                      <option value="turizem/jetese">
                        Turizëm/Jetesë Në Gjermani
                      </option>
                      <option value="call_center">Call Center</option>
                      <option value="mjekesi">Mjekësi</option>
                      <option value="biznes">Biznes</option>
                      <option value="ict">ICT</option>
                    </select>
                  </div>
                  <div className="flex justify-center">
                    <button
                      className="w-[500px] h-[50px] rounded-[15px] bg-purple mt-10 hover:bg-yellow"
                      onClick={() => {
                        setIsLoading(true);
                        submit();
                      }}
                    >
                      {hasLoading ? (
                        <p className="font-poppins font-bold">Loading...</p>
                      ) : (
                        <p className="font-poppins font-bold">
                          Regjistrohu në{" "}
                          <span className="text-neonGreen">TRU Academy</span>
                        </p>
                      )}
                    </button>
                  </div>
                </div>
              ) : (
                <div className="flex justify-center items-center">
                  {hasLoading ? (
                    <ReactLoading
                      type="spinningBubbles"
                      color="#765CE5"
                      height={300}
                      width={200}
                    />
                  ) : (
                    <p className="text-[40px] font-bold text-purple">
                      Regjistrimi u krye me sukses, <br />
                      do ju kontaktojme se shpejti
                    </p>
                  )}
                </div>
              )}
            </div>
          </>
        )}
      </div>
      <ToastContainer autoClose={2000} position="top-center" theme="dark" />
    </section>
  );
};
export default Course;
