/* eslint-disable jsx-a11y/anchor-is-valid */
import { FunctionComponent, useState } from "react";
import useMediaQuery from "../hooks/useMediaQuery";
import ClockLoader from "react-spinners/ClockLoader";

const UpcomingProjects: FunctionComponent = () => {
  const isAboveMediumScreens = useMediaQuery("(min-width: 1060px)");
  let [loading] = useState(true);
  // 295b53
  let color = "#ffff";
  return (
    <section id="upcomingProject" className="mt-2">
      <div className={`flex flex-row ${isAboveMediumScreens && "px-10 py-10"}`}>
        {isAboveMediumScreens ? (
          <>
            <p className="flex justify-start w-[60%] font-bold text-5xl">
              Products
            </p>
            <p className="flex font-bold text-5xl">Projects</p>
          </>
        ) : (
          <></>
        )}
      </div>
      <div
        className={`flex ${
          isAboveMediumScreens ? "flex-row" : "flex-col"
        }  justify-around px-20 `}
      >
        <div className="flex justify-center flex-col items-center ">
          {!isAboveMediumScreens && (
            <p className="flex justify-start font-bold text-5xl mb-2">
              Products
            </p>
          )}
          <p className="text-3xl font-poppins  z-10 text-center md:text-start mb-5">
            Coming Soon
          </p>
          <div>
            <img
              alt="truMix"
              className="w-[180px] absolute"
              src="assets/logotransparent.png"
            />
            <ClockLoader
              color={color}
              loading={loading}
              cssOverride={{ top: "10px" }}
              size={180}
              aria-label="Loading Spinner"
              data-testid="loader"
              speedMultiplier={0.5}
            />
          </div>
          <div className="flex flex-row mt-20">
            <p className="text-4xl font-poppins font-bold z-10 text-center md:text-start text-neonGreen">
              tru
            </p>
            <p className="text-4xl font-poppins font-bold z-10 text-center md:text-start text-purple">
              modoro
            </p>
          </div>
          <div className="flex items-center flex-col w-[300px] text-center">
            <p className="text-xl font-poppins">
              boost and track your productivity with our web/mobile app
              appplication
            </p>
            {/* <p className="text-xl font-poppins">your productivity with our</p> */}
            {/* <p className="text-xl font-poppins">web/mobile app appplication</p> */}
          </div>
          <div className="flex mt-5">
            <img
              alt="truMix"
              className="w-[70px] h-[70px]"
              src="assets/app-store.png"
            />
            <img
              alt="truMix"
              className="w-[70px] h-[70px]"
              src="assets/play-store.png"
            />
          </div>
        </div>
        {/* line */}
        <div className="flex justify-center flex-col items-center border-r-2"></div>

        <div className="flex justify-center flex-col items-center">
          <div
            className={`flex flex-row justify-start  ${
              !isAboveMediumScreens ? "mt-10 mb-12" : ""
            }`}
          >
            {!isAboveMediumScreens && (
              <p className="flex justify-start font-bold text-5xl mb-2">
                Projects
              </p>
            )}
            <p className="text-4xl font-bold font-poppins mb-5"></p>
          </div>
          <div>
            <img alt="truMix" className="w-[180px]" src="assets/autorks.png" />
          </div>
          <div className="flex flex-row mt-20">
            <p className="text-4xl font-poppins font-bold z-10 text-center md:text-start text-[#FA4616]">
              auto
            </p>
            <p className="text-4xl font-poppins font-bold z-10 text-center md:text-start text-white">
              rks
            </p>
          </div>
          <div className="flex items-center flex-col w-[300px] text-center">
            <p className="text-xl font-poppins">
              AutoRKS is the first online marketplace dedicated to cars.
            </p>
            {/* <p className="text-xl font-poppins"> online marketplace</p>
            <p className="text-xl font-poppins">dedicated to cars.</p> */}
          </div>
          <div className="flex mt-5">
            <a
              className="underline hover:text-neonGreen cursor-pointer"
              target="_self"
              href="www.autorks.com"
    
            >
              www.autorks.com
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default UpcomingProjects;
