import React from "react";
import { Disclosure } from "@headlessui/react";
import { Icon } from "@iconify/react";
// import { ChevronUpIcon } from '@heroicons/react/20/solid';

interface FAQItemProps {
  question: string;
  answer: string;
}

function FAQItem({ question, answer }: FAQItemProps) {
  return (
    <Disclosure>
      {({ open }) => (
        <>
          <Disclosure.Button className="flex w-full justify-between mt-2 text-left  font-medium font-poppins text-lg rounded-[23px] border border-purple px-4 py-2 hover:bg-purple hover:cursor-pointer focus-visible:ring focus-visible:ring-purple-500/75">
            <span>{question}</span>

            <Icon
              icon="mingcute:down-line"
              className={`${
                open ? "rotate-180 transform" : ""
              } h-5 w-5 text-purple-500`}
            />
          </Disclosure.Button>
          <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-neonGreen font-poppins">
          <div dangerouslySetInnerHTML={{ __html: answer }} />
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}

export default FAQItem;
